/* eslint-disable no-unused-vars */
import { authedFetch, noAuthFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 * @typedef {Object} ReviewAnswer
 * @property {string} choice
 * @property {string} [explanation]
 * @property {boolean} [requireExplanation]
 *
 * @typedef {Object} CreditApp
 * @property {string} businessLegalName
 * @property {string} yearsInBusiness
 * @property {string} businessType
 * @property {boolean} canProvideGuarantee
 * @property {boolean} unsettledObligations
 * @property {boolean} filledBankruptcy
 * @property {ReviewAnswer} reviewPropertyOwnership
 * @property {ReviewAnswer} reviewNoPropertyOwnershipFound
 * @property {ReviewAnswer} reviewUnsettledObligations
 * @property {ReviewAnswer} reviewDebtIncreaseReason
 * @property {ReviewAnswer} reviewRevenueDecreaseReason
 * @property {ReviewAnswer} reviewFilledBankruptcy
 * @property {ReviewAnswer} reviewCanProvideGuarantee
 * @property {string} contactName
 * @property {string} contactEmail
 * @property {string} [contactPhoneNumber]
 * @property {string} countryCode
 * @property {string} state
 * @property {string} streetAddress
 * @property {string} [unitNumber]
 * @property {string} city
 * @property {string} postalCode
 * @property {Array<{filename: string, status: string}>} [financialDocuments]
 * @property {Array<{filename: string, status: string}>} [otherDocuments]
 *
 *
 */
export const getDataRoomPathForFinancialStatements = (
  lenderName,
  projectName
) => `${lenderName}/${projectName} Data Room/Financial Statements`;

export const getDataRoomPathForAssetDocuments = (lenderName, projectName) =>
  `${lenderName}/${projectName} Data Room/Asset Documents`;

export const isOnOldDataRoomPathForAssetDocuments = (
  dataRoomPath,
  lenderName,
  projectName // legacy projects
) =>
  dataRoomPath?.indexOf(
    `${lenderName} - ${projectName}/Data Room/Project Details`
  ) >= 0;

export const isOnOldDataRoomPathForFinancialStatements = (
  dataRoomPath,
  lenderName,
  projectName // legacy projects
) =>
  dataRoomPath?.indexOf(
    `${lenderName} - ${projectName}/Data Room/Financial Statements`
  ) >= 0;

export const getCreditAppById = async creditAppId => {
  return (
    noAuthFetch(`${apiRoot}/arlo-engine/jsonrpc2`, {
      body: JSON.stringify({
        params: {
          sourceContext: "com.usearlo.app",
          action: "enumerateSubmissions",
          payload: {
            id: creditAppId
          }
        }
      })
    })
      .then(res => res.json())
      .then(data => verifyForErrors(data, "Error on getting credit app"))
      //@ts-ignore
      .then(body => [
        body,
        body?.submissionDetails?.find(({ id }) => id === "creditAppData")
          ?.jsonData
      ])
      .then(([body, creditAppData]) => ({
        creditAppId,
        ...creditAppData,
        dateCreated: body?.dateCreated,
        dateLastUpdated: body?.dateLastUpdated,
        archived: body?.archived,
        financialDocuments: body?.submissionDetails?.filter(
          ({ dataRoomPath }) =>
            dataRoomPath?.indexOf(
              getDataRoomPathForFinancialStatements(
                creditAppData?.customerOrganizationName,
                body?.submissionLabel
              )
            ) >= 0 ||
            isOnOldDataRoomPathForFinancialStatements(
              dataRoomPath,
              creditAppData?.customerOrganizationName,
              body?.submissionLabel
            )
        ),
        assetDocuments: body?.submissionDetails?.filter(
          ({ dataRoomPath }) =>
            dataRoomPath?.indexOf(
              getDataRoomPathForAssetDocuments(
                creditAppData?.customerOrganizationName,
                body?.submissionLabel
              )
            ) >= 0 ||
            isOnOldDataRoomPathForAssetDocuments(
              dataRoomPath,
              creditAppData?.customerOrganizationName,
              body?.submissionLabel
            )
        )
      }))
      .then(verifyForErrors)
  );
};

export const getDefaultRequiredDocuments = () => [
  {
    jsonData: {
      completed: false,
      documentType: "incomeStatement",
      period: 2023,
      submissionTemplateDetailId: "financialStatements-companyTaxReturns"
    },
    label: "Income Statement 2023",
    type: "fileMetadata"
  },
  {
    jsonData: {
      completed: false,
      documentType: "balanceSheet",
      period: 2023,
      submissionTemplateDetailId: "financialStatements-companyTaxReturns"
    },
    label: "Balance Sheet 2023",
    type: "fileMetadata"
  },
  {
    jsonData: {
      completed: false,
      documentType: "taxReturn",
      period: 2023,
      submissionTemplateDetailId: "financialStatements-companyTaxReturns"
    },
    label: "Company Tax Return 2023",
    type: "fileMetadata"
  },
  {
    jsonData: {
      completed: false,
      documentType: "incomeStatement",
      period: 2022,
      submissionTemplateDetailId: "financialStatements-companyTaxReturns"
    },
    label: "Income Statement 2022",
    type: "fileMetadata"
  },
  {
    jsonData: {
      completed: false,
      documentType: "balanceSheet",
      period: 2022,
      submissionTemplateDetailId: "financialStatements-companyTaxReturns"
    },
    label: "Balance Sheet 2022",
    type: "fileMetadata"
  },
  {
    jsonData: {
      completed: false,
      documentType: "taxReturn",
      period: 2022,
      submissionTemplateDetailId: "financialStatements-companyTaxReturns"
    },
    label: "Company Tax Return 2022",
    type: "fileMetadata"
  }
];

export const getCreditAppAsSubmissionDetail = (creditApp, userEmail) => ({
  id: "creditAppData",
  label: "creditAppData",
  jsonData: {
    ...creditApp,
    ...(userEmail ? { userEmail } : {}),
    assetType: "Equipment",
    ...(process.env.REACT_APP_SALES_EMAIL
      ? {
          notificationRecipients: [process.env.REACT_APP_SALES_EMAIL]
        }
      : {})
  },
  type: "jsonObject"
});

export const updateCreditApp = async (
  userEmail,
  {
    submissionSecondaryId,
    submissionLabel,
    financialDocuments, //ignored
    assetDocuments, //ignored
    assetType, //ignored
    archived, //ignored
    dateCreated, //ignored
    dateLastUpdated, //ignored
    ...creditApp
  },
  options = {}
) => {
  const requestProperties = {
    body: JSON.stringify({
      params: {
        sourceContext: "com.usearlo.app",
        action: "updateSubmission",
        payload: {
          ...(creditApp?.creditAppId ? { id: creditApp?.creditAppId } : {}),
          ...(submissionSecondaryId ? { submissionSecondaryId } : {}),
          ...(submissionLabel ? { submissionLabel } : {}),
          submissionDetails: [
            getCreditAppAsSubmissionDetail(creditApp, userEmail)
          ]
        }
      }
    })
  };
  return (
    (
      options?.secured
        ? authedFetch(
            `${apiRoot}/arlo-engine/jsonrpc-secured2`,
            requestProperties
          )
        : noAuthFetch(`${apiRoot}/arlo-engine/jsonrpc2`, requestProperties)
    )
      .then(res => res.json())
      //@ts-ignore
      .then(data => verifyForErrors(data, "Error on updating credit app"))
      .then(body => ({
        creditAppId: body?.payload?.id,
        ...body?.payload?.submissionDetails.find(
          ({ id }) => id === "creditAppData"
        )?.jsonData
      }))
  );
};

/**
 *
 * @param {*} submissionId
 * @param {*} file
 * @param {string} file.filename
 * @param {("file" | "financialDocument" | "incomeStatement" | "balanceSheet" | "taxReturn")} file.type
 * @param {*} jsonData
 * @returns
 */
export const updateCreditAppFile = (
  submissionId,
  { id, label, type, jsonData, ...otherFileProps }
) => {
  const requestProperties = {
    body: JSON.stringify({
      params: {
        sourceContext: "com.usearlo.app",
        action: "updateSubmission",
        payload: {
          id: submissionId,
          submissionDetails: [
            {
              ...(id ? { id } : { id: `file-${label}` }),
              ...(label ? { label } : {}),
              ...(jsonData ? { jsonData } : {}),
              type: type ?? "file",
              ...otherFileProps
            }
          ]
        }
      }
    })
  };
  return (
    noAuthFetch(`${apiRoot}/arlo-engine/jsonrpc2`, requestProperties)
      .then(res => res.json())
      //@ts-ignore
      .then(data => verifyForErrors(data, "Error on updating credit app"))
      .then(body => ({
        creditAppId: body?.payload?.id,
        ...body?.payload?.submissionDetails.find(
          ({ id }) => id === "creditAppData"
        )?.jsonData
      }))
  );
};

export const updateSubmissionDetails = async (
  submissionId,
  submissionDetails
) =>
  !submissionDetails?.length
    ? Promise.resolve()
    : noAuthFetch(`${apiRoot}/arlo-engine/jsonrpc2`, {
        body: JSON.stringify({
          params: {
            sourceContext: "com.usearlo.app",
            action: "updateSubmission",
            payload: {
              id: submissionId,
              submissionDetails
            }
          }
        })
      })
        .then(res => res.json())
        .then(data => verifyForErrors(data, "Error on updating credit app"));

export const removeSubmissionDetail = async (
  submissionId,
  submissionDetailId
) =>
  noAuthFetch(`${apiRoot}/arlo-engine/jsonrpc2`, {
    body: JSON.stringify({
      params: {
        sourceContext: "com.usearlo.app",
        action: "removeSubmissionDetail",
        payload: { submissionId, submissionDetailId }
      }
    })
  })
    .then(res => res.json())
    .then(data => verifyForErrors(data, "Error on removing information"));

export const getSignedUploadUrl = async (creditAppId, filename) => {
  return (
    noAuthFetch(`${apiRoot}/arlo-engine/unsecure-jsonrpc`, {
      body: JSON.stringify({
        method: "com.usearlo.app.documentUploadService",
        params: {
          sourceContext: "com.usearlo.app",
          action: "requestDocumentUpload",
          payload: {
            fundingPackageName: creditAppId,
            fundingDocumentIds: [filename]
          }
        }
      })
    })
      //@ts-ignore
      .then(res => res.json())
      .then(res => res?.data ?? res)
      .then(data => verifyForErrors(data, "Error on preparing file upload"))
      .then(result => ({
        fileKey: result?.[0]?.fileKey,
        presignedUrl: result?.[0]?.presignedUrl
      }))
  );
};

export const uploadFile = (url, file) =>
  fetch(url, {
    method: "PUT",
    body: file
  }).catch(err => {
    console.error("error on uploading file", err);
    return Promise.reject(err);
  });

export const getDocumentCharacterizations = async (creditAppId, filename) =>
  noAuthFetch(`${apiRoot}/arlo-engine/unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.usearlo.app.documentUploadService",
      params: {
        sourceContext: "com.usearlo.app",
        action: "getDocumentCharacterizations",
        payload: {
          fundingPackageName: creditAppId,
          fundingDocumentIds: [filename]
        }
      }
    })
  })
    .then(res => res.json())
    //@ts-ignore
    .then(verifyForErrors);

export const deleteUploadedDocument = async (creditAppId, filename) =>
  noAuthFetch(`${apiRoot}/arlo-engine/unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.usearlo.app.documentUploadService",
      params: {
        sourceContext: "com.usearlo.app",
        action: "deleteDocuments",
        payload: {
          fundingPackageName: creditAppId,
          fundingDocumentIds: [filename]
        }
      }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);

export const getBusinessType = async business_type =>
  noAuthFetch(`${apiRoot}/arlo-engine/jsonrpc`, {
    body: JSON.stringify({
      method: "com.usearlo.app.businessReviewService",
      params: {
        sourceContext: "com.usearlo.app",
        action: "reviewBusiness",
        payload: { business_type }
      }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);

var local = false;
export const getReviewQuestions = async (creditAppId, userEmail) =>
  local
    ? Promise.resolve([
        { name: "businessType" },
        {
          name: "reviewCanProvideGuarantee",
          reasons: ["reason 1", "reason 2", "reason 3"]
        },
        { name: "reviewDebtIncreaseReason", reasons: ["reason"] },
        { name: "reviewRevenueDecreaseReason", reasons: ["reason"] },
        { name: "reviewUnsettledObligations" },
        { name: "reviewFilledBankruptcy" },
        { name: "reviewPropertyOwnership", reasons: ["fulano", "ciclano"] },
        { name: "reviewFinancialDocuments" }
      ])
    : noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
        body: JSON.stringify({
          method: "getReviewQuestions",
          params: { creditAppId, userEmail }
        })
      })
        .then(res => res.json())
        //@ts-ignore
        .then(body => body?.data)
        .then(data =>
          verifyForErrors(
            data,
            "Error on veryifing credit app completeness. Please contact our support for assistance"
          )
        );

export const createSummaryFile = creditAppId =>
  noAuthFetch(`${apiRoot}/funder-operations/external-unsecure-jsonrpc`, {
    body: JSON.stringify({
      method: "com.enpowered.funderoperations.documentExportService",
      params: {
        sourceContext: "com.enpowered.funderoperations",
        action: "exportCharacterizations",
        payload: {
          fundingPackageName: creditAppId
        }
      }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);

export const sendCreditAppViewedEmail = (financingScenarioId, userEmail) =>
  noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
    body: JSON.stringify({
      method: "sendCreditAppViewedEmail",
      params: { financingScenarioId, userEmail }
    })
  })
    .then(res => res.json())
    .then(verifyForErrors);
