import React, { useContext, useEffect, useRef, useState } from "react";
import { MultiStepForm } from "./MultiStepForm";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { CA_STATES, US_STATES } from "../lib/constants";
import { NEW_PROJECT } from "./constants";
import { UserContext } from "../auth/contexts/user";
import { FinancingProposal } from "./FinancingProposal";
import FormNavigator, { VIEW_TYPES } from "./FormNavigator";
import {
  getFinancingScenarioById,
  postFinancingScenario,
  sendToHubspotIfFirstTime
} from "../services/FinancingScenarios";
import BasicInfoStep from "./steps/BasicInfoStep";
import CostsAndSavingsStep from "./steps/CostsAndSavingsStep";
import ImplementationDetailsStep from "./steps/ImplementationDetailsStep";
import SnackBar from "../common/SnackBar";
import { adaptProposalInputsToAnnualValues } from "./FinancingProposal/FinancingScheduleView";
import { Calculator } from "@enpowered/payment-proposal-view";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import CustomerInfoStep from "./steps/CustomerInfo";
import { getInfoMessagesForProperty } from "./InfoMessages";
import { objectsAreDifferent } from "../utils/objectFunctions";
import { getMixpanel } from "../services/mixpanel";
import { MainFrame } from "common/TopBar";
import FeatureNavigator from "./FeatureNavigator";
import {
  generateProposalPdf,
  generateProposalCsv
} from "services/PaymentProposal";
import LineSeparator from "common/LineSeparator";
import "./styles.scss";
import { isDepreciationIncentive } from "utils/incentives";
import ProjectDetailsFeature from "../components/ProjectDetailsFeature";
import LoadingModal from "common/LoadingModal";
import PageNotFound from "../common/PageNotFound";
import {
  getCreditAppById,
  updateCreditApp,
  updateCreditAppFile
} from "../services";
/**
 * @typedef Proposal
 * @property {string} partnerRepId
 * @property {string} partnerRepEmail
 * @property {string} partnerRepName
 * @property {string} projectName
 * @property {string} partnerCompanyId
 * @property {string} partnerCompanyName
 * @property {string} customerCompanyName
 * @property {string} projectDescription
 * @property {string} projectType
 * @property {boolean} isTaxExempt
 * @property {string} [organizationType]
 * @property {string} projectCost
 * @property {string} costSavings
 * @property {string} currentUtilityCost
 * @property {string} otherCostSavings
 * @property {string} OEMCost
 * @property {import("../components/IncentivesField").Incentive[]} incentives
 * @property {string} countryCode
 * @property {string} state
 * @property {string} city
 * @property {string} streetAddress
 * @property {string} unitNumber
 * @property {string} postalCode
 * @property {string} interestRate
 * //Solar fields
 * @property {string} utilityCostEscalator
 * @property {boolean} useEscalatingPayments

 * @property {number} systemDegradationRate
 * @property {number} percentageOfSavings
 * @property {number} termLength
 */

export const PROJECT_ID = "Project ID";
export const START_STEP = "start";

export const FEATURE_PROJECT_ESTIMATE = "Estimate";
export const FEATURE_PROJECT_DETAILS = "Project Details";

const hasError = (errors, fieldName) => errors[fieldName];

const mixpanel = getMixpanel();

export const LabelDetail = ({ children }) => (
  <p className="labelDetail">{children}</p>
);

export const isNewProject = proposal =>
  !proposal.projectId || proposal.projectId === NEW_PROJECT.value;

const adjustProposalValuesToCorrectType = proposal => ({
  ...proposal, //partnerRepName, partnerRepEmail, projectName, projectDescription, customerCompanyName, address (6 fields) and others
  projectCost: +(+proposal.projectCost).toFixed(2),
  costSavings: +(+proposal.costSavings).toFixed(2),
  currentUtilityCost: +(+proposal.currentUtilityCost).toFixed(2),
  downPayment: +(+proposal.downPayment).toFixed(2),
  otherCostSavings: +(+proposal.otherCostSavings).toFixed(2),
  incentives: (proposal.incentives ?? []).map(i => ({
    ...i,
    amount: +(+i.amount ?? 0).toFixed(2),
    ...(isDepreciationIncentive(i.name)
      ? {
          otherYears: +(+i.otherYears).toFixed(2),
          federalTaxRate: +((+i.federalTaxRate ?? 0) / 100),
          stateTaxRate: +((+i.stateTaxRate ?? 0) / 100)
        }
      : {})
  })),
  OEMCost: +(+proposal.OEMCost).toFixed(2),
  projectId: isNewProject(proposal) ? undefined : `${proposal.projectId}`,
  interestRate: proposal.interestRate / 100,
  utilityCostEscalator: proposal.utilityCostEscalator / 100,
  systemDegradationRate: proposal.systemDegradationRate / 100,
  percentageOfSavings: proposal.useEscalatingPayments
    ? proposal.percentageOfSavings / 100
    : 1,
  termLength: proposal.useEscalatingPayments ? undefined : +proposal.termLength
});

const adaptAnnualValuesToGenerator = (proposalInputs, values) => ({
  header: {
    dateProcessed: DateTime.utc().toFormat("MMMM dd, yyyy"),
    project: `${proposalInputs.customerCompanyName}`,
    location: [
      proposalInputs.streetAddress,
      proposalInputs.unitNumber,
      proposalInputs.city,
      proposalInputs.state,
      proposalInputs.postalCode
    ]
      .filter(field => !!field)
      .join(", "),
    ...(proposalInputs.state
      ? {
          stateName: `${
            (proposalInputs.countryCode === "CA" ? CA_STATES : US_STATES).find(
              state => proposalInputs.state === state.value
            )?.label ?? proposalInputs.state
          }`
        }
      : {}),
    description: proposalInputs.projectDescription,
    partner: proposalInputs.partnerCompanyName,
    partnerLogoUrl: proposalInputs.partnerLogoUrl
  },
  project: {
    ...values,
    state: proposalInputs.state
  }
});

const calculationsValidation = Yup.object({
  isPayable: Yup.boolean().test(
    "interest-higher-than-payment",
    "The payments are too low, such that there is no loan principal being paid. Please adjust your inputs to make the financing proposal economical.",
    isPayable => isPayable
  )
});
const basicInfoValidation = Yup.object({
  projectCost: Yup.string()
    .required("Project cost is required")
    .test(
      "not-zero",
      "Project cost must be more than zero",
      projectCost => parseFloat(projectCost) > 0
    ),
  costSavings: Yup.string()
    .required("Project cost is required")
    .test(
      "not-zero",
      "Annual savings must be at least $100",
      costSavings => parseFloat(costSavings) >= 100
    ),
  useEscalatingPayments: Yup.boolean(),
  interestRate: Yup.string()
    .required("Interest rate is required")
    .test(
      "not-zero",
      "Interest rate has to be more than zero",
      interestRate => parseFloat(interestRate) > 0
    ),
  percentageOfSavings: Yup.number().test(
    "percentage-lower-10",
    "Percentage lower than 10 is not allowed",
    (percentageOfSavings, context) =>
      !context.parent.useEscalatingPayments || percentageOfSavings >= 10
  )

  // when("useEscalatingPayments", (useEscalatingPayments, somerhing)=> {
  //   useEscalatingPayments
  //   ? console.log({useEscalatingPayments, somerhing}) ||
  // })
});

export const ThanksStep = ({ email, onClick }) => (
  <div className="thanksStep w-2/3 v-align-middle h-align-middle">
    <h3 className="title">Thanks for your submission</h3>
    <p className="information">
      Your proposal will be emailed to {email} shortly.
    </p>
    <p className="information">
      You may safely close this window, or start a new proposal request.
    </p>
    <button
      className="newRequest h-align-middle"
      onClick={onClick}
      type="button"
    >
      Start a new request
    </button>
  </div>
);

var stepsData = {};
const setStepNumbers = startStep => {
  for (
    let i = 1, step_i = stepsData[startStep].next;
    i < Object.keys(stepsData).length && stepsData[step_i];
    i++, step_i = stepsData[step_i].next
  ) {
    stepsData[step_i].stepNumber = i;
  }
};

const mixpanelEventNames = {
  [START_STEP]: "Payments Proposal Request - Customer Info",
  basicProjectValues: "Payments Proposal Request - Basic Project Values",
  costsAndSavings: "Payments Proposal Request - Cost and Savings",
  implementationDetails: "Payments Proposal Request - Implementation Details"
};

const goToSpecificStep = async (
  financingScenarioId = "",
  stepCompleted,
  nextStep,
  setStep,
  proposal
) => {
  trackStepCompleted(financingScenarioId, stepCompleted, proposal);
  setStep(nextStep);
};

const trackStepCompleted = (financingScenarioId, stepCompleted, proposal) => {
  if (stepCompleted && mixpanelEventNames[stepCompleted])
    mixpanel.track(mixpanelEventNames[stepCompleted], {
      Partner: proposal?.partnerCompanyName,
      [PROJECT_ID]: financingScenarioId
    });
};

const validationOnChanged = (
  hasError,
  setErrors,
  stepName,
  proposal,
  fieldProps
) => {
  if (hasError) validateStep(setErrors, stepName, proposal, fieldProps);
};

const projectDetailsAlwaysMandatoryFields = {
  projectName: Yup.string().required("Project Name is required"),
  countryCode: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  projectType: Yup.string().required("Project Type is required")
};
const projectDetailsBuildEstimateMandatoryFields = {
  projectCost: Yup.string()
    .required("Project cost is required")
    .test(
      "not-zero",
      "Project cost must be more than zero",
      projectCost => parseFloat(projectCost) > 0
    ),
  costSavings: Yup.string()
    .required("Project cost is required")
    .test(
      "not-zero",
      "Annual savings must be at least $100",
      costSavings => parseFloat(costSavings) >= 100
    )
};

const projectDetailsShareCreditAppMandatoryFields = {
  projectCost: Yup.string()
    .required("Project cost is required")
    .test(
      "not-zero",
      "Project cost must be more than zero",
      projectCost => parseFloat(projectCost) > 0
    ),
  assetDocuments: Yup.array()
    .required("At least one document is required")
    .min(1, "At least one document is required")
};
const projectDetailsBuildEstimateValidation = Yup.object({
  ...projectDetailsAlwaysMandatoryFields,
  ...projectDetailsBuildEstimateMandatoryFields
});
const projectDetailsShareCreditAppValidation = Yup.object({
  ...projectDetailsAlwaysMandatoryFields,
  ...projectDetailsShareCreditAppMandatoryFields
});
const projectDetailsValidateAll = Yup.object({
  ...projectDetailsAlwaysMandatoryFields,
  ...projectDetailsBuildEstimateMandatoryFields,
  ...projectDetailsShareCreditAppMandatoryFields
});

const startStepValidation = Yup.object({
  customerCompanyName: Yup.string().required(
    "Customer Company Name is required"
  ),
  organizationType: Yup.string().required("Organization Type is required")
});

const setValidationErrors = (setErrors, err) => {
  setErrors(
    err
      ? Object.fromEntries(
          err.map(({ message, path }) => [
            path,
            message.indexOf("must be a `number`") > -1
              ? "Only numbers are allowed"
              : message
          ])
        )
      : {}
  );
  return false;
};

export const ACTION_BUILD_ESTIMATE = "Build Estimate";
export const ACTION_SHARE_CREDIT_APP = "Share Credit App";

const validateProjectDetails = (action, setErrors, proposal) =>
  (action === ACTION_BUILD_ESTIMATE
    ? projectDetailsBuildEstimateValidation.validate(proposal, {
        abortEarly: false
      })
    : action === ACTION_SHARE_CREDIT_APP
      ? projectDetailsShareCreditAppValidation.validate(proposal, {
          abortEarly: false
        })
      : projectDetailsValidateAll.validate(proposal, {
          abortEarly: false
        })
  )

    .then(() => {
      setErrors({});
      return true;
    })
    .catch(err => setValidationErrors(setErrors, err?.inner));
/**
 *
 * @param {string} stepName
 * @param {object} proposal
 * @returns {Promise<Boolean>}
 */
const validateStep = (setErrors, stepName, proposal) =>
  (stepName === START_STEP
    ? startStepValidation.validate(proposal, { abortEarly: false })
    : stepName === "basicProjectValues"
      ? basicInfoValidation.validate(proposal, { abortEarly: false })
      : stepName === "calculations"
        ? calculationsValidation.validate(proposal, { abortEarly: false })
        : Promise.reject()
  )
    .then(() => {
      setErrors({});
      return true;
    })
    .catch(err => setValidationErrors(setErrors, err?.inner));

const getUserInfo = user =>
  user?.email
    ? {
        partnerRepEmail: user.email,
        partnerRepName: user.fullName
      }
    : user;

/**
 *
 * @param {object} props
 * @param {object?} props.partnerCompany
 * @param {array<object>} props.projectList
 * @param {function} props.refetchProjects
 * @returns
 */
export const ProposalForm = ({
  partnerCompany,
  refetchProjects,
  isLoadingPartnerCompany,
  noProjectsYet
}) => {
  const navigate = useNavigate();
  const { financingScenarioId } = useParams();

  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [step, setStep] = useState(START_STEP);
  const [errors, setErrors] = useState({});
  const [infoAssistOn, setInfoAssistOn] = useState(true);
  const [fieldFocused, setFieldFocused] = useState("");
  const [isMessageSnackBarOpen, setIsMessageSnackBarOpen] = useState(false);
  const setMessage = (text, type = "info") => {
    setSnackbarMessage({ text, type });
    setIsMessageSnackBarOpen(true);
  };

  const userLoggedIn = getUserInfo(useContext(UserContext).user);
  const [proposal, setProposal] = useState({
    ...{
      countryCode: "",
      state: "",
      projectType: "",
      partnerCompanyName: "",
      projectName: "",
      customerCompanyName: "",
      projectDescription: "",
      financingOption: "Loan",
      isTaxExempt: false,
      organizationType: "",
      utilityCostEscalator: "3.6",
      interestRate: "8.5",
      projectCost: "0.00",
      costSavings: "0.00",
      currentUtilityCost: "0.00",
      otherCostSavings: "0.00",
      incentives: [
        {
          name: "ITC",
          percentage: 30,
          amount: 0,
          useInPayments: true
        },
        {
          name: "CCA"
        }
      ],
      OEMCost: "0.00",
      constructionPeriodLength: 3,
      estimatedConstructionStartDate: DateTime.local().toISODate(),
      progressPayments: { 1: "50", final: "50" },
      downPayment: 0,
      systemDegradationRate: 0.5,
      percentageOfSavings: 95,
      useEscalatingPayments: false,
      termLength: 10,
      wasAlreadyExportedOnce: false,
      isArchived: false
    },
    //default values for both existing and new scenarios
    isPayable: false
  });

  const setFieldValue = (fieldName, value) =>
    setProposal(lastObject => ({
      ...proposal,
      ...lastObject,
      [fieldName]: value
    }));

  const handleBlur = () =>
    proposal.projectName &&
    proposal.projectName !== "" &&
    postScenarioUpdates(proposal);

  const setValues = newValues =>
    setProposal(lastObject => ({
      ...proposal,
      ...lastObject,
      ...newValues
    }));
  const formProps = {
    setFieldValue,
    values: proposal,
    setValues,
    handleBlur
  };
  const {
    data: financingScenario = {},
    refetch: refetchProject,
    isLoading: isLoadingProject
  } = useQuery(
    `financing-scenario-${financingScenarioId}`,
    async () =>
      financingScenarioId &&
      getFinancingScenarioById(financingScenarioId).then(data => {
        if (!data.isArchived && financingScenario.isArchived) {
          setMessage("This project was restored by another user");
        }
        setValues(data);
        return data;
      })
  );

  const [creditApp, setCreditApp] = useState({});
  const { isLoading: isLoadingCreditApp } = useQuery(
    `credit-app-${financingScenario?.creditAppId}`,
    async () =>
      financingScenario?.creditAppId &&
      getCreditAppById(financingScenario?.creditAppId).then(data => {
        if (!data?.archived && creditApp?.archived) {
          setMessage("This deal was restored by another user");
        }
        setCreditApp(last => ({ ...data, ...last }));
        return data;
      })
  );
  useEffect(() => {
    setProposal(lastObject => ({
      ...proposal,
      ...lastObject,
      ...financingScenario
    }));
  }, [financingScenario.financingScenarioId]);

  useEffect(() => {
    setProposal(lastObject => ({
      ...proposal,
      ...lastObject,
      partnerCompanyId:
        financingScenario?.partnerCompanyId ?? partnerCompany?.partnerCompanyId,
      partnerCompanyName:
        financingScenario?.partnerCompanyName ??
        partnerCompany?.partnerCompanyName,
      partnerLogoUrl: `https://${
        process.env.REACT_APP_PARTNER_COMPANIES_IMAGES_BUCKET_CLOUDFRONT_URL
      }/${
        financingScenario?.partnerLogoFilename ??
        partnerCompany?.partnerLogoFilename
      }`
    }));
    setCreditApp(lastObject => ({
      ...creditApp,
      ...lastObject,
      customerOrganizationName:
        financingScenario?.partnerCompanyName ??
        partnerCompany?.partnerCompanyName,
      customerOrganizationId:
        financingScenario?.partnerCompanyId ?? partnerCompany?.partnerCompanyId,
      customerOrganizationLogoUrl: `https://${
        process.env.REACT_APP_PARTNER_COMPANIES_IMAGES_BUCKET_CLOUDFRONT_URL
      }/${
        financingScenario?.partnerLogoFilename ??
        partnerCompany?.partnerLogoFilename
      }`
    }));
  }, [partnerCompany?.partnerCompanyId]);

  const lastRenderProposal = useRef(proposal);
  const modalOverlayRef = useRef();
  useEffect(() => {
    step !== START_STEP && validateStep(setErrors, "calculations", proposal);
  }, [proposal.isPayable]);

  const { mutate: postScenarioUpdates, isLoading: isStillSubmitting } =
    useMutation(postFinancingScenario, {
      onError: err => {
        setErrors({ ...errors, "Update Project": err });
      },
      onMutate: () => {}
    });

  const fieldLabels = {
    projectCost: "Project Cost",
    termLength: "Term Length",
    interestRate: "Interest Rate",
    percentageOfSavings: "Percentage of Savings",
    currentUtilityCost: "Annual Utility Cost",
    OEMCost: "Annual O&M Cost",
    constructionPeriodLength: "Construction Period Length",
    progressPayments: "Progress Payments",
    downPayment: "Down Payment",
    systemDegradationRate: "System Degradation Rate",
    utilityCostEscalator: "Utility Cost Escalator",
    estimatedConstructionStartDate: "Estimated Construction Start Date",
    otherCostSavings: "Other Cost Savings"
  };

  const proposalInputsForCalculator = adaptProposalInputsToAnnualValues(
    adjustProposalValuesToCorrectType(proposal)
  );
  //------is it payable? -----
  const calculateProposal = () => Calculator(proposalInputsForCalculator);

  const verifyIncentivesValuesAndUpdate = (
    projectCost,
    incentives,
    depreciation
  ) => {
    let updatedIncentives = incentives;
    let hasAnythingChanged = false;
    if (
      incentives.some(
        i => !!i.percentage && i.amount !== projectCost * (i.percentage / 100)
      )
    ) {
      updatedIncentives = incentives.map(i =>
        i.percentage ? { ...i, amount: projectCost * (i.percentage / 100) } : i
      );
      hasAnythingChanged = true;
    }
    let idx = updatedIncentives.findIndex(i => isDepreciationIncentive(i.name));
    if (
      idx >= 0 &&
      depreciation.length &&
      (updatedIncentives[idx].amount !== depreciation[0] ||
        updatedIncentives[idx].otherYears !==
          depreciation?.slice(1)?.reduce((ac, i) => ac + i, 0))
    ) {
      updatedIncentives[idx] = {
        ...updatedIncentives[idx],
        amount: depreciation?.[0],
        otherYears: depreciation?.slice(1)?.reduce((ac, i) => ac + i, 0)
      };
      hasAnythingChanged = true;
    }
    if (hasAnythingChanged) {
      setFieldValue("incentives", updatedIncentives);
    }
  };

  const setIsPayable = newIsPayable => {
    if (newIsPayable !== proposal.isPayable) {
      setFieldValue("isPayable", newIsPayable);
    }
  };
  const calculations = useRef({ schedule: {} });
  const areRequiredInputsAvailable =
    +proposal.projectCost > 0 &&
    +proposal.costSavings >= 100 &&
    +proposal.interestRate > 0 &&
    proposal.projectType;

  const checkRequiredInfoAndCalculateProposal = () => {
    if (
      Object.keys(calculations.current.schedule).length === 0 ||
      objectsAreDifferent(proposal, lastRenderProposal.current)
    ) {
      lastRenderProposal.current = { ...proposal };
      if (areRequiredInputsAvailable)
        try {
          lastRenderProposal.current = {
            ...proposal,
            incentives: (proposal.incentives ?? [])?.map(i => ({
              ...i
            }))
          };
          calculations.current = calculateProposal();
          setFieldValue("termLengthInMonths", calculations.current.termMonths);
          verifyIncentivesValuesAndUpdate(
            proposal.projectCost,
            proposal.incentives,
            calculations.current.schedule?.depreciation
          );
          setIsPayable(true);
        } catch {
          setIsPayable(false);
        }
    }
  };
  checkRequiredInfoAndCalculateProposal();

  stepsData = {
    [START_STEP]: {
      next: "basicProjectValues",
      content: (
        <CustomerInfoStep
          formProps={formProps}
          validationOnChanged={(fieldName, value) =>
            validationOnChanged(
              hasError(errors, fieldName),
              setErrors,
              START_STEP,
              {
                ...proposal,
                [fieldName]: value
              }
            )
          }
          hasError={fieldName => hasError(errors, fieldName)}
          startClicked={async () => {
            if (!(await validateStep(setErrors, START_STEP, proposal)))
              return null;
            mixpanel.time_event(mixpanelEventNames.submit);
            setStep("basicProjectValues");
          }}
          infoAssistOn={infoAssistOn}
          setInfoAssistOn={setInfoAssistOn}
          proposalInputsForCalculator={proposalInputsForCalculator}
        />
      ),
      isValid: () => validateStep(setErrors, START_STEP, proposal),
      stepLabel: "Customer Info"
    },
    basicProjectValues: {
      next: "costsAndSavings",
      prev: START_STEP,
      content: (
        <BasicInfoStep
          formProps={formProps}
          errors={errors}
          hasError={hasError}
          validationOnChanged={(fieldName, value) => {
            if (
              (+proposal.projectCost > 0 && fieldName === "costSavings") ||
              (+proposal.costSavings > 0 && fieldName === "projectCost") ||
              (+proposal.projectCost > 0 && +proposal.costSavings > 0)
            )
              validateStep(setErrors, "basicProjectValues", {
                ...proposal,
                [fieldName]: value
              });
          }}
          setStep={setStep}
          infoAssistOn={infoAssistOn}
          fieldFocused={fieldFocused}
          setFieldFocused={setFieldFocused}
          updateProposal={postScenarioUpdates}
          calculateProposal={checkRequiredInfoAndCalculateProposal}
          proposalInputsForCalculator={proposalInputsForCalculator}
        />
      ),
      isValid: () => validateStep(setErrors, "basicProjectValues", proposal),
      stepLabel: "Basic Project Values"
    },
    costsAndSavings: {
      next: "implementationDetails",
      prev: "basicProjectValues",
      content: (
        <CostsAndSavingsStep
          formProps={formProps}
          errors={errors}
          hasError={hasError}
          infoAssistOn={infoAssistOn}
          fieldFocused={fieldFocused}
          setFieldFocused={setFieldFocused}
        />
      ),
      stepLabel: "Cost & Savings"
    },
    implementationDetails: {
      next: "partnerCompanyName",
      prev: "costsAndSavings",
      content: (
        <ImplementationDetailsStep
          formProps={formProps}
          errors={errors}
          hasError={hasError}
          infoAssistOn={infoAssistOn}
          fieldFocused={fieldFocused}
          setFieldFocused={setFieldFocused}
        />
      ),
      stepLabel: "Implementation Details"
    }
  };
  setStepNumbers(START_STEP);

  const onRestoreClicked = () => {
    setFieldValue("isArchived", false);
    postScenarioUpdates({ ...proposal, isArchived: false });
  };

  const [viewType, setViewType] = useState(VIEW_TYPES.FINANCING_SCHEDULE);

  const [isLoadingPdfMessageVisible, setIsLoadingPdfMessageVisible] =
    useState(false);
  const { isLoading: isGeneratingPdf, mutate: generatePdf } = useMutation(
    () =>
      generateProposalPdf(
        adaptAnnualValuesToGenerator(
          adjustProposalValuesToCorrectType(proposal),
          proposalInputsForCalculator
        )
      ),
    {
      onMutate: () => {
        setIsLoadingPdfMessageVisible(true);
      },
      onSettled: () => {
        setIsLoadingPdfMessageVisible(false);
      },
      onSuccess: () =>
        sendToHubspotIfFirstTime(postScenarioUpdates, proposal, setFieldValue)
    }
  );

  const [featureSelected, setFeatureSelected] = useState(
    FEATURE_PROJECT_DETAILS
  );

  const mixpanelTrack = (msg, additionalProps) =>
    getMixpanel().track(msg, {
      "Project ID": proposal.financingScenarioId,
      partnerCompanyId: proposal.partnerCompanyId,
      ...additionalProps
    });

  const setErrorMessageAndOpen = (text, type = "error") => {
    setSnackbarMessage({ text, type });
    setIsMessageSnackBarOpen(true);
  };
  return (
    <MainFrame>
      {isLoadingPartnerCompany || isLoadingProject || isLoadingCreditApp ? (
        <LoadingModal />
      ) : !isLoadingProject &&
        !financingScenario.financingScenarioId &&
        !noProjectsYet ? (
        <PageNotFound />
      ) : (
        <div className="block h-full">
          <div>
            <SnackBar
              key={"ErrorsAndProposalPro"}
              messages={
                Object.entries(errors)?.length
                  ? Object.entries(errors).map(([, message]) => message)
                  : infoAssistOn
                    ? getInfoMessagesForProperty(proposal, fieldFocused)
                    : []
              }
              type={
                Object.entries(errors)?.length || !infoAssistOn
                  ? "error"
                  : "info"
              }
              fieldLabel={fieldLabels[fieldFocused]}
              duration={5000}
            />
            <SnackBar
              key={"specificMessages"}
              messages={snackbarMessage?.text ? [snackbarMessage.text] : []}
              type={snackbarMessage?.type}
              isOpen={isMessageSnackBarOpen}
              setIsOpen={setIsMessageSnackBarOpen}
              duration={5000}
            />
          </div>
          <div ref={modalOverlayRef}>
            <div className="navAndContent">
              <FeatureNavigator
                onBackClicked={refetchProjects}
                isArchived={proposal.isArchived}
                navigate={navigate}
                items={[
                  {
                    label: FEATURE_PROJECT_DETAILS,
                    onClick: () => {
                      setFeatureSelected(FEATURE_PROJECT_DETAILS);
                    },
                    isSelected: featureSelected === FEATURE_PROJECT_DETAILS
                  },
                  {
                    label: FEATURE_PROJECT_ESTIMATE,
                    onClick: () =>
                      (featureSelected === FEATURE_PROJECT_DETAILS
                        ? validateProjectDetails(
                            ACTION_BUILD_ESTIMATE,
                            setErrors,
                            proposal
                          )
                        : Promise.resolve(true)
                      ).then(
                        valid =>
                          valid && setFeatureSelected(FEATURE_PROJECT_ESTIMATE)
                      ),
                    isSelected: featureSelected === FEATURE_PROJECT_ESTIMATE
                  }
                ]}
              />
              {featureSelected === FEATURE_PROJECT_DETAILS ? (
                <ProjectDetailsFeature
                  formProps={formProps}
                  handleBlur={() => {
                    handleBlur();
                    proposal.projectName &&
                      proposal.projectName !== "" &&
                      updateCreditApp(null, creditApp, { secured: true });
                  }}
                  hasError={fieldName => hasError(errors, fieldName)}
                  validationOnChanged={(fieldName, value) =>
                    hasError(errors, fieldName) &&
                    validateProjectDetails("All", setErrors, {
                      ...proposal,
                      assetDocuments: creditApp?.assetDocuments ?? [],
                      [fieldName]: value
                    })
                  }
                  proposalInputsForCalculator={proposalInputsForCalculator}
                  setMessage={setMessage}
                  mixpanelTrack={mixpanelTrack}
                  updateFinancingScenario={async val =>
                    postScenarioUpdates({
                      financingScenarioId: proposal.financingScenarioId,
                      projectName: proposal.projectName,
                      partnerCompanyId: proposal.partnerCompanyId,
                      ...val
                    })
                  }
                  creditApp={creditApp}
                  setCreditAppState={val => {
                    setCreditApp(last => ({
                      ...creditApp,
                      ...last,
                      ...val
                    }));
                  }}
                  updateCreditAppFile={fileInfo =>
                    updateCreditAppFile(creditApp.creditAppId, fileInfo)
                  }
                  onBuildEstimateClicked={() => {
                    setFeatureSelected(FEATURE_PROJECT_ESTIMATE);
                  }}
                  errors={errors}
                  validateProjectDetails={action =>
                    validateProjectDetails(action, setErrors, {
                      ...proposal,
                      assetDocuments: creditApp?.assetDocuments ?? []
                    })
                  }
                  onRestoreClicked={onRestoreClicked}
                  setErrorMessageAndOpen={setErrorMessageAndOpen}
                  refetchProject={refetchProject}
                  userLoggedIn={userLoggedIn}
                />
              ) : (
                <form className="mb-8" onBlur={handleBlur}>
                  <div
                    className={`flex flex-col window form-window-default-size`}
                  >
                    <div className="relative mb-4">
                      <FormNavigator
                        currentStepName={step}
                        onStepClicked={async stepName => {
                          setFieldFocused("");
                          if (
                            !stepsData[step].isValid ||
                            (await stepsData[step].isValid())
                          )
                            goToSpecificStep(
                              proposal.financingScenarioId,
                              step,
                              stepName,
                              setStep,
                              proposal
                            );
                        }}
                        steps={stepsData}
                        viewType={viewType}
                        setViewType={setViewType}
                        otherActions={""}
                        onRestoreClicked={onRestoreClicked}
                        setMessage={setMessage}
                        proposal={proposal}
                        setStep={setStep}
                      />
                      <LineSeparator className="absolute top-10" />
                    </div>
                    {step === START_STEP ? (
                      <MultiStepForm
                        steps={stepsData}
                        currentStep={stepsData[step]}
                        currentStepName={step}
                        setCurrentStep={setStep}
                        isSubmitting={isStillSubmitting}
                      />
                    ) : (
                      <FinancingProposal
                        proposalInputs={adjustProposalValuesToCorrectType(
                          proposal
                        )}
                        calculations={calculations.current}
                        hasError={hasError}
                        errors={errors}
                        areRequiredInputsAvailable={areRequiredInputsAvailable}
                        isPayable={proposal.isPayable}
                        setMessage={setMessage}
                        viewType={viewType}
                        isLoadingPdfMessageVisible={isLoadingPdfMessageVisible}
                        setIsLoadingPdfMessageVisible={
                          setIsLoadingPdfMessageVisible
                        }
                        adaptAnnualValuesToGenerator={
                          adaptAnnualValuesToGenerator
                        }
                        infoAssistOn={infoAssistOn}
                        setInfoAssistOn={setInfoAssistOn}
                        generatePdf={generatePdf}
                        isGeneratingPdf={isGeneratingPdf}
                        generateCsv={(...props) => {
                          generateProposalCsv(...props);
                          sendToHubspotIfFirstTime(
                            postScenarioUpdates,
                            proposal,
                            setFieldValue
                          );
                        }}
                      >
                        <MultiStepForm
                          steps={stepsData}
                          currentStep={stepsData[step]}
                          currentStepName={step}
                          setCurrentStep={setStep}
                          isSubmitting={isStillSubmitting}
                          className={"mt-6 mb-6 ml-4"}
                        />
                      </FinancingProposal>
                    )}
                  </div>
                  <div className="h-10"></div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </MainFrame>
  );
};
